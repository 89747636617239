import Layout from '@components/layout'
import { useMediaQuery } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/styles'
import makeStyles from '@material-ui/styles/makeStyles'
import renderBlok from '@renderBlok'
import renderBloks from '@renderBloks'
import { Button, ContentContainer, PageContainer, ProfileCard } from '@system'
import ACC_THEME from '@themes'
import { graphql, useStaticQuery } from 'gatsby'
import React, { useState } from 'react'
import SbEditable from 'storyblok-react'

const useStyles = makeStyles(() => ({
  championsIndexGrid: {
    display: 'grid',
    gap: '16px',
    gridTemplateColumns: '1fr 1fr',
    padding: '32px 0',
    [ACC_THEME.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    [ACC_THEME.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      padding: '56px 0',
    },
    [ACC_THEME.breakpoints.up('lg')]: {
      gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
    },
  },
  championsIndexMobileButton: {
    color: ACC_THEME.palette.common.black,
    display: 'block',
    margin: '8px auto 0',
    '& > span': {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      fontSize: '16px',
      fontWeight: ACC_THEME.typography.fontWeightRegular,
      gap: '4px',
      textDecoration: 'underline',
      textTransform: 'none',
      '& > span': {
        margin: '0',
        '& > svg': {
          transform: 'rotate(-90deg)',
        },
      },
    },
  },
}))

const ChampionsIndex = ({ blok, location }) => {
  const staticQueryData = useStaticQuery(graphql`
    query {
      championDetailEntries: allStoryblokEntry(
        filter: { field_component: { eq: "championsDetail" } }
      ) {
        edges {
          node {
            full_slug
            content
          }
        }
      }
      championDetailModuleEntry: storyblokEntry(
        full_slug: { eq: "us/_content-types/champions-detail-modules" }
      ) {
        content
        field_component
      }
    }
  `)
  let { body } = blok
  const { fullSlug, hero, metaData } = blok
  const year = fullSlug === 'us/champion/' ? '2024' : fullSlug.split('/')[2]
  const championsDetailEntries = staticQueryData.championDetailEntries.edges
    .filter(({ node: { full_slug } }) => full_slug.includes(year))
    .map(({ node: { content, full_slug, id } }) => {
      const { firstName, headshot, lastName, company, jobTitle } =
        JSON.parse(content)
      return {
        company,
        firstName,
        fullSlug: full_slug,
        headshot,
        id,
        jobTitle,
        lastName,
      }
    })
  const championDetailModuleEntry = {
    ...JSON.parse(staticQueryData.championDetailModuleEntry.content),
    component: staticQueryData.championDetailModuleEntry.field_component,
  }
  const classes = useStyles()
  const isHandheldDevice = useMediaQuery(ACC_THEME.breakpoints.down('sm'))
  const [mobileChampionsVisible, setMobileChampionsVisible] = useState(6)

  const champions = isHandheldDevice
    ? championsDetailEntries.slice(0, mobileChampionsVisible)
    : championsDetailEntries

  const loadMoreChampions = () => {
    setMobileChampionsVisible(
      (previousMobileChampionsVisible) => previousMobileChampionsVisible + 6
    )
  }
  // Support ability to control the position of the champion module in the body.
  // Add a 'box' component with the ID field value set to 'champion-module'.
  const modulePlacementDefined = body.some(
    (bodyBlok) => bodyBlok?.id === 'champion-module'
  )
  if (modulePlacementDefined) {
    body = body.map((bodyBlok) => {
      if (bodyBlok?.id === 'champion-module') {
        return championDetailModuleEntry
      }
      return bodyBlok
    })
  }

  return (
    <Layout metaData={metaData}>
      <ThemeProvider theme={ACC_THEME}>
        <CssBaseline />
        <SbEditable content={blok}>
          <PageContainer>
            {hero?.length > 0 && renderBloks(hero)}
            <ContentContainer>
              {championsDetailEntries?.length > 0 && (
                <>
                  <div className={classes.championsIndexGrid}>
                    {champions.map((champion) => (
                      <ProfileCard key={champion.id} {...champion} />
                    ))}
                  </div>
                  {isHandheldDevice &&
                    mobileChampionsVisible < championsDetailEntries.length && (
                      <Button
                        className={classes.championsIndexMobileButton}
                        icon="ChevronLeft"
                        onClick={() => loadMoreChampions()}
                      >
                        More Champions
                      </Button>
                    )}
                </>
              )}
            </ContentContainer>
          </PageContainer>
          {body?.length > 0 && renderBloks(body)}
          {!modulePlacementDefined &&
            championDetailModuleEntry &&
            renderBlok(championDetailModuleEntry)}
        </SbEditable>
      </ThemeProvider>
    </Layout>
  )
}

export default ChampionsIndex
